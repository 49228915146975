import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const proveedoresContext = createContext();

export const useProveedores = () => {
  const context = useContext(proveedoresContext);
  if (!context) throw new Error("Proveedor Provider is missing");
  return context;
};

export const ProveedorProvider = ({ children }) => {
  const [proveedores, setProveedores] = useState([]);

  const getProveedores = async () => {
    try {
      const res = await axios.get("https://server-pos-8ea8.onrender.com/api/proveedores");
      setProveedores(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getProveedores();
  }, []);

  const deleteProveedor = async (id) => {
    try {
      const res = await axios.delete("https://server-pos-8ea8.onrender.com/api/proveedores/" + id);
      if (res.status === 204) {
        setProveedores(proveedores.filter((proveedor) => proveedor._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createProveedor = async (proveedor) => {
    try {
      const res = await axios.post("https://server-pos-8ea8.onrender.com/api/proveedores", proveedor);
      setProveedores([...proveedores, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getProveedor = async (id) => {
    try {
      const res = await axios.get("https://server-pos-vkb9.onrender.com/api/proveedores/" + id);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateProveedor = async (id, proveedor) => {
    try {
      const res = await axios.put("https://server-pos-vkb9.onrender.com/api/proveedores/" + id, proveedor);
      setProveedores(proveedores.map((proveedor) => (proveedor._id === id ? res.data : proveedor)));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <proveedoresContext.Provider
      value={{ proveedores, deleteProveedor, createProveedor, getProveedor, updateProveedor }}
    >
      {children}
    </proveedoresContext.Provider>
  );
};