import React, { useState } from "react";
import './Login.scss';
import { IoEnter } from "react-icons/io5";
import { useUsuarios } from '../../context/usuarioContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { PiLockKey } from "react-icons/pi";

export function LoginView() {
  const { getEmpleadoByUsername } = useUsuarios();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async () => {
    try {
      setLoading(true);
      const empleado = await getEmpleadoByUsername(username);
      if (empleado && empleado.contraseña === password) {
        const { _id, cargo, sede } = empleado;
        login({ username, password, cargo, sede });
        localStorage.setItem('user', JSON.stringify({ username, password, cargo, sede, userId: _id }));
        
        if (cargo === 'cocinera') {
          navigate('/Cocina');
        } else {
          navigate('/services');
        }
      } else {
        setErrorMessage('Credenciales incorrectas');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error.message);
      setErrorMessage('Hubo un problema al iniciar sesión. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <>
      <div className='toda w-full'>
        <div className='left-contentt'>
          <div className="min-h-screen flex flex-col justify-center items-center">
            <div className='content'>
              <div className='inicio flex flex-col justify-center items-center'>
                <div className='seccion-titulo md:flex md:w-full'>
                  <div className='contenedor-login mt-0 sm:mt-0'>
                    <div className="contenedor-credenciales">
                      <h1 className="text-8xl mb-10 sm:text-8xl md:text-9xl lg:text-9xl xl:text-9xl title">Mercurio</h1>
                      <div className="linea-ingresar">
                        <div className="recuadro-input">
                          <FaUser />
                          <input placeholder="USUARIO" value={username} 
                            onChange={e => setUsername(e.target.value.toLowerCase())} 
                            className="input-credenciales">
                          </input>
                        </div>

                        <div className="recuadro-input">
                          <PiLockKey />
                          <input placeholder="CONTRASEÑA" className="input-credenciales" type="password" inputMode="numeric" value={password} onChange={e => setPassword(e.target.value)}></input>
                        </div>

                        <button className='play' onClick={handleLogin} disabled={loading}>
                          {loading ? <AiOutlineLoading3Quarters className="spin" /> : <IoEnter />}
                        </button>

                      </div>
                      
                      {errorMessage && <p className="error-message">{errorMessage}</p>}
                      <div className="ingresar-boton">
                      </div>
                    </div>
                    <div className='flex powered-by'>
                      <h1 className='train'> <span className='trainMini'> powered by </span> EN ORBITA | Mercurio</h1>
                    </div>
                  </div>
                  <div className='contenedor2-login mt-0 sm:mt-0'>
                    <div className="contenedor-credenciales">
                      <div className="circulo1"></div>
                      <div className="circulo2"></div>
                      <div className="circulo3"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
