import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const usuarioContext = createContext();

export const useUsuarios = () => {
  const context = useContext(usuarioContext);
  if (!context) throw new Error("Usuario Provider is missing");
  return context;
};

export const UsuarioProvider = ({ children }) => {
  const [usuarios, setUsuarios] = useState([]);

  const getUsuarios = async () => {
    try {
      const res = await axios.get("https://server-pos-8ea8.onrender.com/api/empleados");
      setUsuarios(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUsuarios();
  }, []);

  const deleteUsuario = async (id) => {
    try {
      const res = await axios.delete(`https://server-pos-8ea8.onrender.com/api/empleados/${id}`);
      if (res.status === 204) {
        setUsuarios(usuarios.filter((usuario) => usuario._id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const createUsuario = async (usuario) => {
    try {
      const res = await axios.post("https://server-pos-8ea8.onrender.com/api/empleados", usuario);
      setUsuarios([...usuarios, res.data]);
    } catch (error) {
      console.error(error);
    }
  };

  const getUsuario = async (id) => {
    try {
      const res = await axios.get(`https://server-pos-8ea8.onrender.com/api/empleados/id/${id}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const updateUsuario = async (id, usuario) => {
    try {
      const res = await axios.put(`https://server-pos-8ea8.onrender.com/api/empleados/${id}`, usuario);
      setUsuarios(usuarios.map((usuario) => (usuario._id === id ? res.data : usuario)));
    } catch (error) {
      console.error(error);
    }
  };

  // Función para obtener empleado por nombre de usuario
  const getEmpleadoByUsername = async (username) => {
    try {
      const res = await axios.get(`https://server-pos-8ea8.onrender.com/api/empleados/username/${username}`);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <usuarioContext.Provider
      value={{ usuarios, deleteUsuario, createUsuario, getUsuario, updateUsuario, getEmpleadoByUsername }}
    >
      {children}
    </usuarioContext.Provider>
  );
};